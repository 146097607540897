export const globalClasses = {
  active: '.Mui-active',
  checked: '.Mui-checked',
  completed: '.Mui-completed',
  disabled: '.Mui-disabled',
  error: '.Mui-error',
  expanded: '.Mui-expanded',
  focusVisible: '.Mui-focusVisible',
  focused: '.Mui-focused',
  readOnly: '.Mui-readOnly',
  required: '.Mui-required',
  selected: '.Mui-selected',
} as const;
