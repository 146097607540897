import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getUtmSource } from '../store/selectors';
import { markUtmSourceAsOrganic as doMarkUtmSourceAsOrganic } from '../store/thunks';

export function useUserUtmTags() {
  const dispatch = useDispatch();

  const utmSource = useSelector(getUtmSource);

  const markUtmSourceAsOrganic = useCallback(() => {
    dispatch(doMarkUtmSourceAsOrganic());
  }, [dispatch]);

  return {
    utmSource,
    markUtmSourceAsOrganic,
  };
}
