import { isObjectEmpty } from 'src/core/common/utils/isObjectEmpty';
import { ContentManagerSocialNetwork } from 'src/core/contentManager/entities';

export type renderSocialNetworkMetaDataType = typeof renderSocialNetworkMetaData;

export const renderSocialNetworkMetaData = {
  twitter: (social: ContentManagerSocialNetwork, canonicalURL: string) => {
    if (!social || isObjectEmpty(social)) return null;

    return (
      <>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={canonicalURL} />
        <meta name="twitter:title" content={social.title} />
        <meta name="twitter:description" content={social.description} />
        {!!social.image && (
          <>
            <meta name="twitter:image:src" content={social.image.url} />
            <meta name="twitter:image:width" content={social.image.width.toString()} />
            <meta name="twitter:image:height" content={social.image.height.toString()} />
            <meta name="twitter:image:alt" content={social.image.alt} />
          </>
        )}
      </>
    );
  },
};
