/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnchorHTMLAttributes, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = NextLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: ReactNode;
    className?: string;
    href: string;
  };

const Link = ({ children, href, target, className = '', prefetch, ...otherProps }: LinkProps) => {
  return (
    <NextLink {...otherProps} target={target} className={className} href={href} prefetch={prefetch}>
      {children}
    </NextLink>
  );
};

export default Link;
