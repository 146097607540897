import { styled } from '@mui/material/styles';
import { Switch as MaterialSwitch } from '@mui/material';
import { globalClasses } from 'src/core/common/constants/classes';

const PREFIX = 'MuiSwitch';

export const classes = {
  switchBase: `.${PREFIX}-switchBase`,
  thumb: `.${PREFIX}-thumb`,
  track: `.${PREFIX}-track`,
  checked: `.${PREFIX}-checked`,
};

export const StyledMaterialSwitch = styled(MaterialSwitch)`
  width: 64px;
  height: 32px;
  padding: 0;
  display: flex;

  ${classes.switchBase} {
    padding: 2px;
    
    &${globalClasses.checked} {
      transform: translateX(32px);
      
      ${classes.thumb} {
        background-color: var(--star);
        background-image: url("/images/light_theme_icon.png");
      }
      
      & + ${classes.track} {
        opacity: 1;
        background-color: var(--primary);
      }
    },
  },
  
  ${classes.track} {
    border-radius: 49px;
    opacity: 1;
    background-color: #8E8CF0;
},

  ${classes.thumb} {
    width: 28px;
    height: 28px;
    border-radius: 51px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #000;
    background-image: url("/images/dark_theme_icon.png");
  },
`;
