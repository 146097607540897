import { AFFILIATE_SOURCES } from 'src/core/user/entities';
import { useUserUtmTags } from './useUserUtmTags';

export const useIsAffiliateSource = () => {
  const { utmSource } = useUserUtmTags();

  if (!utmSource) return false;

  return AFFILIATE_SOURCES.includes(utmSource);
};
