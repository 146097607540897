import cn from 'classnames';
import { ReactNode, useState } from 'react';
import Link from 'src/core/common/components/common/Link';
import RenderOnlyOnClient from 'src/core/common/components/common/RenderOnlyOnClient';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { useLogin } from 'src/core/auth/hooks';
import { PreferredTheme } from 'src/core/theme/entities';
import useTheme from 'src/core/theme/hooks/useTheme';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import ThemeSwitch from 'src/core/theme/ui/atoms/ThemeSwitch';
import { ContentManagerHeader as ContentManagerHeaderEntity } from 'src/core/contentManager/entities';
import { useHeader } from 'src/core/contentManager/hooks';
import { useResolution } from 'src/core/common/hooks';
import HeaderNavigationMenu from '../../molecules/HeaderNavigationMenu';
import ContentManagerLogo from '../../atoms/ContentManagerLogo';
import HeaderDrawer from '../HeaderDrawer';
import styles from './Header.module.scss';

export type HeaderProps = {
  className?: string;
  header: ContentManagerHeaderEntity;
  banner?: ReactNode;
};

const Header = ({ className, header, banner = null }: HeaderProps) => {
  const { getLoginPath } = useLogin();
  const { selectLink, selectSignIn } = useHeader();
  const { isDesktop } = useResolution();
  const [shouldLoadNavigationMenu, setShouldLoadNavigationMenu] = useState(false);
  const { currentTheme, changeTheme, otherTheme } = useTheme();

  const loadNavigationMenu = () => {
    if (isDesktop) {
      setShouldLoadNavigationMenu(true);
    }
  };

  return (
    <header className={cn(styles.root, className)} onMouseEnter={loadNavigationMenu}>
      {banner}
      <nav className={styles.nav}>
        <HeaderDrawer header={header} className={styles.headerMenu} onLinkSelect={selectLink} />
        <div className={styles.logoMobile}>
          <Link href={BASE_ROUTES.HOME} aria-label="Go to Nebula homepage">
            <ContentManagerLogo size="mobile" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.logoDesktop}>
          <Link href={BASE_ROUTES.HOME} aria-label="Go to Nebula homepage">
            <ContentManagerLogo className={styles.logo} size="desktop" />
          </Link>
        </div>

        {isDesktop && (
          <ul className={styles.navLinkList}>
            {header.data.map((item) => (
              <HeaderNavigationMenu
                key={`${item.label}-${item.href}`}
                item={item}
                onLinkSelect={selectLink}
                shouldLoad={shouldLoadNavigationMenu}
              />
            ))}
          </ul>
        )}

        <ul className={styles.navActionsList}>
          <li className={cn(styles.navActionItem, styles.switchActionItem)}>
            <RenderOnlyOnClient>
              <ThemeSwitch
                checked={currentTheme === PreferredTheme.LIGHT}
                onChange={changeTheme}
                aria-label={`Change theme to ${otherTheme}`}
              />
            </RenderOnlyOnClient>
          </li>
          <li className={styles.navActionItem}>
            <Link
              className={styles.navAction}
              href={getLoginPath()}
              onClick={selectSignIn}
              data-testid="sign-in-header-button"
            >
              Sign In
              <Icon name="arrow" className={styles.navActionIcon} />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
