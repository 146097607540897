import { useServices } from 'src/core/common/hooks';
import { HeaderLinkClickEvent, SignInClickEvent } from '../analytics/events';

export function useHeader() {
  const { analyticsService } = useServices();

  const selectLink = (link: string) => {
    analyticsService.track(new HeaderLinkClickEvent({ link }));
  };

  const selectSignIn = () => {
    analyticsService.track(new SignInClickEvent());
  };

  return {
    selectLink,
    selectSignIn,
  };
}
