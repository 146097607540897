import cn from 'classnames';
import { useState } from 'react';
import { useFooter } from 'src/core/contentManager/hooks/useFooter';
import { config } from 'src/config';
import Link from 'src/core/common/components/common/Link';
import AppLink from 'src/core/common/components/common/AppLink';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { getCurrentYear } from 'src/core/common/utils/date';
import PaymentMethodsLogos from 'src/core/common/components/common/PaymentMethodsLogos';
import SocialLink, {
  SocialLinkProps,
} from 'src/core/contentManager/components/molecules/SocialLink';
import { useCookieConsent } from 'src/core/common/hooks';
import RenderOnlyOnClient from 'src/core/common/components/common/RenderOnlyOnClient';
import { LocationCountryCase, LocationCountrySwitch } from 'src/core/location/ui';
import { LocationCountry, countriesWithConsentCheck } from 'src/core/location/entities';
import { ContentManagerFooter } from 'src/core/contentManager/entities';
import ContentManagerLogo from '../../atoms/ContentManagerLogo';
import styles from './Footer.module.scss';

type FooterProps = {
  footer: ContentManagerFooter;
};

const SOCIAL_LINKS: Array<{ href: string; type: SocialLinkProps['type'] }> = [
  {
    href: config.technicalLinks.nebula.instagram,
    type: 'instagram',
  },
  {
    href: config.technicalLinks.nebula.tiktok,
    type: 'tiktok',
  },
  {
    href: config.technicalLinks.nebula.facebook,
    type: 'facebook',
  },
  {
    href: config.technicalLinks.nebula.threads,
    type: 'threads',
  },
  {
    href: config.technicalLinks.nebula.twitter,
    type: 'twitter',
  },
];

const NATIVE_APP_LINKS = ['android', 'ios'] as const;

const Footer = ({ footer }: FooterProps) => {
  const { toggleReconsent } = useCookieConsent();
  const { selectLink, selectAppDownloadLink } = useFooter();
  const [year] = useState<number>(() => getCurrentYear());

  return (
    <footer className={styles.root}>
      <nav className={styles.content}>
        <ul className={styles.linkList}>
          <li className={cn(styles.linkGroup, styles.socialsLinkGroup)}>
            <Link
              href={BASE_ROUTES.HOME}
              aria-label="Go to AskNebula homepage"
              className={styles.mainLink}
            >
              <ContentManagerLogo className={styles.mainLogo} size="desktop" />
            </Link>
            <span className={styles.divider} aria-hidden />
            <ul className={styles.socialsList}>
              {SOCIAL_LINKS.map(({ href, type }) => (
                <li className={styles.socialItem} key={href}>
                  <SocialLink href={href} type={type} ariaLabel={`Nebula on ${type}`} />
                </li>
              ))}
            </ul>
            <ul className={styles.downloadLinksList}>
              {NATIVE_APP_LINKS.map((platform) => (
                <li className={styles.downloadLinkItem} key={platform}>
                  <AppLink
                    platform={platform}
                    linkProps={{
                      onSelect: selectAppDownloadLink,
                    }}
                    imageProps={{
                      className: styles.downloadLinkImage,
                    }}
                  />
                </li>
              ))}
            </ul>
          </li>
          <li className={styles.ownLinksGroup}>
            <ul className={styles.ownLinksGroupList}>
              {footer.data.map(({ label: parentLabel, children }) => (
                <li key={parentLabel} className={styles.linkGroup}>
                  <p className={styles.linkGroupTitle}>{parentLabel}</p>
                  <ul className={styles.linkGroupList}>
                    {children.map(
                      ({ label, href }) =>
                        href && (
                          <li key={href} className={styles.linkListItem}>
                            <Link
                              className={styles.link}
                              href={href}
                              onClick={() => selectLink(href)}
                            >
                              {label}
                            </Link>
                          </li>
                        ),
                    )}
                    {parentLabel === 'Support' ? (
                      <RenderOnlyOnClient>
                        <LocationCountrySwitch>
                          <LocationCountryCase value={countriesWithConsentCheck}>
                            <li className={styles.linkListItem}>
                              <button className={styles.button} onClick={toggleReconsent}>
                                Consent settings
                              </button>
                            </li>
                          </LocationCountryCase>
                        </LocationCountrySwitch>
                      </RenderOnlyOnClient>
                    ) : null}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <hr className={styles.underline} />
        <PaymentMethodsLogos className={styles.paymentMethods} />
        <RenderOnlyOnClient>
          <LocationCountrySwitch>
            <LocationCountryCase value={LocationCountry.US}>
              <small className={styles.copyright}>Yolo Brothers Inc.</small>
            </LocationCountryCase>
            <LocationCountryCase default>
              <small className={styles.copyright}>
                {year} {config.legalEntity.name}. © All rights reserved.
              </small>
            </LocationCountryCase>
          </LocationCountrySwitch>
        </RenderOnlyOnClient>
      </nav>
    </footer>
  );
};

export default Footer;
