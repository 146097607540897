import { createContext, ReactNode } from 'react';
import { PreferredTheme } from 'src/core/theme/entities';
import useThemeProvider from 'src/core/theme/hooks/useThemeProvider';
import { mapDomElementsWithModification } from 'src/core/common/utils/mapDomElementsWithModification';

export type ThemeStateType = PreferredTheme;
export type ThemeUpdaterType = (theme: PreferredTheme) => void;

export const ThemeStateContext = createContext<ThemeStateType>(PreferredTheme.DARK);
export const ThemeUpdaterContext = createContext<ThemeUpdaterType>(() => null);

export type ThemeProviderProps = {
  children: ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useThemeProvider();

  const modifiedChildren = mapDomElementsWithModification(children, { className: 'themeRoot' });
  return (
    <ThemeStateContext.Provider value={theme}>
      <ThemeUpdaterContext.Provider value={setTheme}>
        {modifiedChildren}
      </ThemeUpdaterContext.Provider>
    </ThemeStateContext.Provider>
  );
};

export default ThemeProvider;
