import cn from 'classnames';
import { config } from 'src/config';
import styles from './DefaultContentManagerBannerText.module.scss';

export type DefaultContentManagerBannerTextProps = {
  className?: string;
};

export const DefaultContentManagerBannerText = ({
  className = '',
}: DefaultContentManagerBannerTextProps) => {
  return (
    <>
      <p className={cn(styles.root, styles.desktop, className)}>
        <strong>{config.defaultFreeMinutes} Minutes FREE + 80% OFF For New Customers</strong>
      </p>
      <p className={cn(styles.root, styles.mobile, className)}>
        <strong>{config.defaultFreeMinutes} Minutes FREE + 80% OFF</strong> <br />
        <span className={styles.mobileDescription}> For New Customers</span>
      </p>
    </>
  );
};

export default DefaultContentManagerBannerText;
