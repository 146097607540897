import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { FlowName } from 'src/core/quiz/entities';
import { signUpViaFacebook } from 'src/core/user/store/thunks';
import { normalizeError } from 'src/core/common/utils/errors';
import { useLogger } from 'src/core/common/hooks';
import { FacebookLoginData } from '../entities';

export const useFacebookSignUp = (flowName: FlowName) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  const logger = useLogger();

  const signUpFacebook = useCallback(async (data: FacebookLoginData): Promise<void> => {
    if ('status' in data) {
      logger.error(new Error('Facebook login failed'));
      return;
    }

    setIsDisabled(true);

    const { accessToken, email } = data as ReactFacebookLoginInfo;

    const result = {
      token: accessToken,
      quizName: flowName,
      email: email,
    };

    try {
      await dispatch(signUpViaFacebook(result));
      setIsDisabled(false);
    } catch (e) {
      // TODO think about error message
      const err = normalizeError(e);
      logger.error(err);
      setIsDisabled(false);
    }
  }, []);

  return {
    isDisabled,
    signUpFacebook,
  };
};
