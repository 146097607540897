import cn from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import Link from 'src/core/common/components/common/Link';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './ContentManagerBanner.module.scss';

export type ContentManagerBannerProps = {
  className?: string;
  renderIcon?: (className?: string) => ReactNode;
  renderText?: () => ReactNode;
  linkText: string;
  linkHref: string;
  onLinkSelect: (event: MouseEvent<HTMLAnchorElement>) => void;
};

export const ContentManagerBanner = ({
  className = '',
  renderIcon,
  renderText,
  linkText,
  linkHref,
  onLinkSelect,
}: ContentManagerBannerProps) => {
  return (
    <div className={cn(styles.root, className)}>
      {renderIcon ? (
        renderIcon(styles.icon)
      ) : (
        <Icon name="clock" className={styles.icon} title="clock" />
      )}
      {renderText && <div className={styles.text}>{renderText()}</div>}
      <Link
        href={linkHref}
        className={styles.link}
        onClick={onLinkSelect}
        data-testid="content-manager-banner-link"
      >
        {linkText}
      </Link>
    </div>
  );
};

export default ContentManagerBanner;
