import { UtmSource } from './User';

export const AFFILIATE_SOURCES: Array<string> = [
  UtmSource.AFFILIATE,
  UtmSource.MOONSHOT,
  UtmSource.CPAMATICA,
  UtmSource.MYSTICMAG,
  UtmSource.DEMETRAMEDIA,
  UtmSource.NATURALINT,
  UtmSource.FLEX,
  UtmSource.MAXIME,
  UtmSource.EXPLA,
];
