import { MouseEventHandler } from 'react';
import appStoreIcon from 'public/images/native-app/appstore_icon.png';
import googlePlayIcon from 'public/images/native-app/google_play_icon.png';
import { config } from 'src/config';
import Image from 'src/core/common/components/common/Image';
import Link from 'src/core/common/components/common/Link';

export type AppLinkProps = {
  platform: 'ios' | 'android';
  linkProps: {
    onSelect: (platform: AppLinkProps['platform']) => void;
    className?: string;
  };
  imageProps: {
    className?: string;
  };
};

const platforms = {
  ios: {
    src: appStoreIcon,
    alt: 'Download on the App Store',
    href: config.technicalLinks.nebula.appStore,
    dataTestId: 'appstore-button',
  },
  android: {
    src: googlePlayIcon,
    alt: 'Get it on Google Play',
    href: config.technicalLinks.nebula.googlePlay,
    dataTestId: 'googleplay-button',
  },
};

const AppLink = ({ platform, linkProps, imageProps }: AppLinkProps) => {
  const { className: linkClassName = '', onSelect } = linkProps;
  const { className: imageClassName = '' } = imageProps;
  const selectLink: MouseEventHandler<HTMLAnchorElement> = () => {
    onSelect(platform);
  };
  return (
    <Link
      className={linkClassName}
      href={platforms[platform].href}
      onClick={selectLink}
      data-testid={platforms[platform].dataTestId}
    >
      <Image
        type="future"
        src={platforms[platform].src}
        alt={platforms[platform].alt}
        className={imageClassName}
      />
    </Link>
  );
};

export default AppLink;
