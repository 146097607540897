import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSignInByTokenStatus } from 'src/core/user/store/selectors';
import { signInByTokenReset } from 'src/core/user/store/actions';
import { signInByToken } from 'src/core/user/store/thunks';
import { parseQueryParams } from 'src/core/common/utils/query';

export function useSignInByToken() {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getSignInByTokenStatus);

  const doSignInByToken = useCallback(() => {
    const { token } = parseQueryParams<{ token?: string }>();

    dispatch(signInByToken(token));
  }, [dispatch]);

  const resetSignInByTokenStatus = useCallback(() => {
    dispatch(signInByTokenReset());
  }, [dispatch]);

  return {
    signInByToken: doSignInByToken,
    loadingStatus,
    resetSignInByTokenStatus,
  };
}
