import { useCallback, useState } from 'react';
import { useIdleModal, useRouter, useServices } from 'src/core/common/hooks';
import { checkIsClientSide } from 'src/core/common/utils/checkIsClientSide';
import { DAY, SECOND } from 'src/core/common/constants';
import { getDatesDifference } from 'src/core/common/utils/dates/getDatesDifference';
import { isValidDate } from 'src/core/common/utils/date';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { SeoFreeReadingModalClickEvent, SeoFreeReadingModalOpenEvent } from '../analytics/events';

const IDLE_MODAL_TIMER_MS = 10 * SECOND;
const IDLE_MODAL_STORAGE_KEY = 'askNebula/seo-last-seen-idle-modal';
const IDLE_MODAL_SHOW_AGAIN_AFTER = DAY * 30;

export type UseLastSeenModalParams = {
  onConfirm?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  shouldSkip?: boolean;
};

export const useLastSeenModal = ({
  onConfirm,
  onClose,
  onOpen,
  shouldSkip,
}: UseLastSeenModalParams) => {
  const router = useRouter();
  const { persistentStorageService, analyticsService } = useServices();

  const [shouldStartCountDown, setShouldStartCountdown] = useState(() => {
    if (checkIsClientSide()) {
      const lastSeenDate = persistentStorageService.get<string | null>(IDLE_MODAL_STORAGE_KEY);
      const today = Date.now();

      if (isValidDate(lastSeenDate)) {
        return getDatesDifference(lastSeenDate, today) > IDLE_MODAL_SHOW_AGAIN_AFTER;
      }

      return true;
    }

    return false;
  });

  const onConfirmDefault = () => {
    analyticsService.track(new SeoFreeReadingModalClickEvent({ context: 'confirm' }));
    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  const onCloseDefault = () => {
    analyticsService.track(new SeoFreeReadingModalClickEvent({ context: 'close' }));
  };

  const onModalOpen = useCallback(() => {
    persistentStorageService.set(IDLE_MODAL_STORAGE_KEY, Date.now());
    setShouldStartCountdown(false);

    if (onOpen) {
      onOpen();
      return;
    }

    analyticsService.track(new SeoFreeReadingModalOpenEvent());
  }, [persistentStorageService, onOpen, analyticsService]);

  const { showModal, confirmModal, closeModal, clearModalShowTimer } = useIdleModal(
    IDLE_MODAL_TIMER_MS,
    {
      skip: shouldSkip || !shouldStartCountDown,
      open: onModalOpen,
      confirm: onConfirm ?? onConfirmDefault,
      close: onClose ?? onCloseDefault,
    },
  );

  return {
    showModal,
    confirmModal,
    closeModal,
    clearModalShowTimer,
  };
};
