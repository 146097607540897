import { useServices } from 'src/core/common/hooks';
import {
  FooterLinkClickEvent,
  FooterAppClickEvent,
} from 'src/core/contentManager/analytics/events';

export function useFooter() {
  const { analyticsService } = useServices();

  const selectLink = (link: string) => {
    analyticsService.track(new FooterLinkClickEvent({ link }));
  };

  const selectAppDownloadLink = (context: 'ios' | 'android') => {
    analyticsService.track(new FooterAppClickEvent({ context }));
  };

  return {
    selectLink,
    selectAppDownloadLink,
  };
}
