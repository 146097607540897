import { createContext, ReactNode } from 'react';

export type LastSeenModalState = {
  cancel: () => void;
};

export const LastSeenModalContext = createContext<LastSeenModalState>({ cancel: () => null });

export type LastSeenModalProviderProps = {
  children: ReactNode;
  cancel: () => void;
};

const LastSeenModalProvider = ({ children, cancel }: LastSeenModalProviderProps) => {
  return (
    <LastSeenModalContext.Provider value={{ cancel }}>{children}</LastSeenModalContext.Provider>
  );
};

export default LastSeenModalProvider;
