import { ContentManagerHeader } from '../entities';

export const defaultHeader: NonNullable<ContentManagerHeader> = {
  data: [
    {
      href: '',
      label: 'Psychic Readings',
      children: [
        {
          href: '/tarot-reading',
          label: 'Tarot reading',
          children: [],
        },
        {
          href: '/astrology-reading',
          label: 'Astrology Reading',
          children: [],
        },
        {
          href: '/aura-reading',
          label: 'Aura Reading',
          children: [],
        },
        {
          href: '/numerology-reading',
          label: 'Numerology Reading',
          children: [],
        },
        {
          href: '/palm-reading',
          label: 'Palm Reading',
          children: [],
        },
        {
          href: '/cartomancy-reading',
          label: 'Cartomancy Reading',
          children: [],
        },
        {
          href: '/latin-tarot-reading',
          label: 'Latin Tarot Reading',
          children: [],
        },
        {
          href: '/lotus-tarot-reading',
          label: 'Lotus Tarot Reading',
          children: [],
        },
        {
          href: '/phone-psychic-reading',
          label: 'Phone Psychic Reading',
          children: [],
        },
        {
          href: '/email-psychic-reading',
          label: 'Email Psychic Reading',
          children: [],
        },
      ],
    },
    {
      href: '',
      label: 'Psychics',
      children: [
        {
          href: '/online-psychic',
          label: 'Online Psychic',
          children: [],
        },
        {
          href: '/free-psychic-reading',
          label: 'Free Psychic Reading',
          children: [],
        },
        {
          href: '/cheap-psychic',
          label: 'Cheap Psychic',
          children: [],
        },
        {
          href: '/ask-psychic',
          label: 'Ask Psychic',
          children: [],
        },
        {
          href: '/psychic-chat',
          label: 'Psychic Chat',
          children: [],
        },
        {
          href: '/talk-to-psychic',
          label: 'Talk to Psychic',
          children: [],
        },
        {
          href: '/psychic-near-me',
          label: 'Psychic Near Me',
          children: [],
        },
      ],
    },
    {
      href: '',
      label: 'Topics',
      children: [
        {
          href: '/love-reading',
          label: 'Love Reading',
          children: [],
        },
        {
          href: '/fortune-reading',
          label: 'Fortune Reading',
          children: [],
        },
        {
          href: '/past-life-reading',
          label: 'Past Life Reading',
          children: [],
        },
        {
          href: '/career-reading',
          label: 'Career Reading',
          children: [],
        },
        {
          href: '/lost-objects-reading',
          label: 'Lost Objects Reading',
          children: [],
        },
        {
          href: '/pregnancy-reading',
          label: 'Fertility Reading',
          children: [],
        },
        {
          href: '/soulmate-reading',
          label: 'Soulmate Reading',
          children: [],
        },
        {
          href: '/marriage-reading',
          label: 'Marriage Reading',
          children: [],
        },
      ],
    },
    {
      href: '',
      label: 'Zodiac Signs',
      children: [
        {
          href: '',
          label: 'Zodiac Compatibility',
          children: [
            {
              href: '/zodiac-compatibility',
              label: 'All Zodiac Signs',
              children: [],
            },
            {
              href: '/zodiac-compatibility/aquarius',
              label: 'Aquarius',
              children: [],
            },
            {
              href: '/zodiac-compatibility/aries',
              label: 'Aries',
              children: [],
            },
            {
              href: '/zodiac-compatibility/cancer',
              label: 'Cancer',
              children: [],
            },
            {
              href: '/zodiac-compatibility/capricorn',
              label: 'Capricorn',
              children: [],
            },
            {
              href: '/zodiac-compatibility/gemini',
              label: 'Gemini',
              children: [],
            },
            {
              href: '/zodiac-compatibility/leo',
              label: 'Leo',
              children: [],
            },
            {
              href: '/zodiac-compatibility/libra',
              label: 'Libra',
              children: [],
            },
            {
              href: '/zodiac-compatibility/pisces',
              label: 'Pisces',
              children: [],
            },
            {
              href: '/zodiac-compatibility/sagittarius',
              label: 'Sagittarius',
              children: [],
            },
            {
              href: '/zodiac-compatibility/scorpio',
              label: 'Scorpio',
              children: [],
            },
            {
              href: '/zodiac-compatibility/taurus',
              label: 'Taurus',
              children: [],
            },
            {
              href: '/zodiac-compatibility/virgo',
              label: 'Virgo',
              children: [],
            },
          ],
        },
        {
          href: '',
          label: 'Zodiac Sign Traits',
          children: [
            {
              href: '/zodiac-sign/aquarius',
              label: 'Aquarius',
              children: [],
            },
            {
              href: '/zodiac-sign/aries',
              label: 'Aries',
              children: [],
            },
            {
              href: '/zodiac-sign/cancer',
              label: 'Cancer',
              children: [],
            },
            {
              href: '/zodiac-sign/capricorn',
              label: 'Capricorn',
              children: [],
            },
            {
              href: '/zodiac-sign/gemini',
              label: 'Gemini',
              children: [],
            },
            {
              href: '/zodiac-sign/leo',
              label: 'Leo',
              children: [],
            },
            {
              href: '/zodiac-sign/libra',
              label: 'Libra',
              children: [],
            },
            {
              href: '/zodiac-sign/pisces',
              label: 'Pisces',
              children: [],
            },
            {
              href: '/zodiac-sign/sagittarius',
              label: 'Sagittarius',
              children: [],
            },
            {
              href: '/zodiac-sign/scorpio',
              label: 'Scorpio',
              children: [],
            },
            {
              href: '/zodiac-sign/taurus',
              label: 'Taurus',
              children: [],
            },
            {
              href: '/zodiac-sign/virgo',
              label: 'Virgo',
              children: [],
            },
          ],
        },
        {
          href: '',
          label: 'Woman Zodiac Sign Traits',
          children: [
            {
              href: '/zodiac-sign/aquarius/woman',
              label: 'Aquarius',
              children: [],
            },
            {
              href: '/zodiac-sign/aries/woman',
              label: 'Aries',
              children: [],
            },
            {
              href: '/zodiac-sign/cancer/woman',
              label: 'Cancer',
              children: [],
            },
            {
              href: '/zodiac-sign/capricorn/woman',
              label: 'Capricorn',
              children: [],
            },
            {
              href: '/zodiac-sign/gemini/woman',
              label: 'Gemini',
              children: [],
            },
            {
              href: '/zodiac-sign/leo/woman',
              label: 'Leo',
              children: [],
            },
            {
              href: '/zodiac-sign/libra/woman',
              label: 'Libra',
              children: [],
            },
            {
              href: '/zodiac-sign/pisces/woman',
              label: 'Pisces',
              children: [],
            },
            {
              href: '/zodiac-sign/sagittarius/woman',
              label: 'Sagittarius',
              children: [],
            },
            {
              href: '/zodiac-sign/scorpio/woman',
              label: 'Scorpio',
              children: [],
            },
            {
              href: '/zodiac-sign/taurus/woman',
              label: 'Taurus',
              children: [],
            },
            {
              href: '/zodiac-sign/virgo/woman',
              label: 'Virgo',
              children: [],
            },
          ],
        },
        {
          href: '',
          label: 'Daily Horoscope',
          children: [
            {
              href: '/horoscope/daily/aquarius',
              label: 'Aquarius',
              children: [],
            },
            {
              href: '/horoscope/daily/aries',
              label: 'Aries',
              children: [],
            },
            {
              href: '/horoscope/daily/cancer',
              label: 'Cancer',
              children: [],
            },
            {
              href: '/horoscope/daily/capricorn',
              label: 'Capricorn',
              children: [],
            },
            {
              href: '/horoscope/daily/gemini',
              label: 'Gemini',
              children: [],
            },
            {
              href: '/horoscope/daily/leo',
              label: 'Leo',
              children: [],
            },
            {
              href: '/horoscope/daily/libra',
              label: 'Libra',
              children: [],
            },
            {
              href: '/horoscope/daily/pisces',
              label: 'Pisces',
              children: [],
            },
            {
              href: '/horoscope/daily/sagittarius',
              label: 'Sagittarius',
              children: [],
            },
            {
              href: '/horoscope/daily/scorpio',
              label: 'Scorpio',
              children: [],
            },
            {
              href: '/horoscope/daily/taurus',
              label: 'Taurus',
              children: [],
            },
            {
              href: '/horoscope/daily/virgo',
              label: 'Virgo',
              children: [],
            },
          ],
        },
      ],
    },
    {
      href: '',
      label: 'Articles',
      children: [
        {
          href: '/blog',
          label: 'Blog',
          children: [],
        },
        {
          href: '/meaning/tarot-card',
          label: 'Tarot Cards Meaning',
          children: [],
        },
      ],
    },
  ],
};
