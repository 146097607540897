import { lazy, MouseEvent, Suspense, useState } from 'react';
import cn from 'classnames';
import { ContentManagerHeader } from 'src/core/contentManager/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import Link from 'src/core/common/components/common/Link';
import styles from './HeaderNavigationMenu.module.scss';

const LazyNavigationMenu = lazy(() => import('src/core/common/components/common/NavigationMenu'));

export type MenuHoverEvent = (event: MouseEvent<HTMLElement>) => void;

type HeaderNavigationMenuProps = {
  className?: string;
  item: NonNullable<ContentManagerHeader>['data'][number];
  onLinkSelect: (link: string) => void;
  shouldLoad: boolean;
};

const HeaderNavigationMenu = ({
  item,
  className = '',
  onLinkSelect,
  shouldLoad,
}: HeaderNavigationMenuProps) => {
  const [opened, setOpened] = useState(false);
  const openMenu = () => setOpened(true);
  const closeMenu = () => setOpened(false);

  const renderTrigger = (onMouseOver?: MenuHoverEvent) => {
    return (
      <button className={styles.trigger} onMouseOver={onMouseOver}>
        {item.label}
      </button>
    );
  };

  const renderItem = (itemToRender: HeaderNavigationMenuProps['item']) => {
    const selectLink = () => {
      onLinkSelect(itemToRender.href);
      closeMenu();
    };

    return itemToRender.href ? (
      <Link
        href={itemToRender.href}
        className={styles.itemTrigger}
        prefetch={false}
        onClick={selectLink}
      >
        {itemToRender.label}
      </Link>
    ) : (
      <span className={styles.itemTrigger}>{itemToRender.label}</span>
    );
  };

  const renderItemTrigger = (itemToRender: HeaderNavigationMenuProps['item']) => {
    return (
      <button className={styles.itemTrigger}>
        {itemToRender.label} <Icon name="arrow-simple" className={styles.triggerArrow} />
      </button>
    );
  };

  if (!item.children.length) {
    return (
      <li className={cn(styles.root, className)}>
        <button className={styles.trigger}>{item.label}</button>
      </li>
    );
  }

  return (
    <li className={cn(styles.root, className)}>
      <Suspense fallback={<button className={styles.trigger}>{item.label}</button>}>
        {shouldLoad ? (
          <LazyNavigationMenu
            isOpen={opened}
            open={openMenu}
            close={closeMenu}
            mode="hover"
            className={styles.menu}
            item={item}
            renderItem={renderItem}
            renderTrigger={renderTrigger}
            renderItemTrigger={renderItemTrigger}
          />
        ) : (
          <button className={styles.trigger}>{item.label}</button>
        )}
      </Suspense>
    </li>
  );
};

export default HeaderNavigationMenu;
