import cn from 'classnames';
import Link from 'src/core/common/components/common/Link';
import { config } from 'src/config';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './FixedSupportLink.module.scss';

export type FixedSupportLinkProps = {
  className?: string;
};

export const FixedSupportLink = ({ className = '' }: FixedSupportLinkProps) => {
  return (
    <Link
      className={cn(styles.root, className)}
      href={config.technicalLinks.nebula.support}
      target="_blank"
      data-testid="help-fixed-button"
    >
      <Icon name="question-circled" className={styles.icon} /> Help
    </Link>
  );
};

export default FixedSupportLink;
