import { MouseEvent } from 'react';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { useRouter, useServices } from 'src/core/common/hooks';

export const useDefaultContentManagerBanner = () => {
  const router = useRouter();
  const { analyticsService } = useServices();

  const onLinkSelect = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    analyticsService.seoBannerClick();

    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  const getBannerProps = () => ({
    linkText: 'Try now',
    linkHref: BASE_ROUTES.SIMPLE_REGISTRATION_LANDING,
    onLinkSelect,
  });

  return {
    getBannerProps,
  };
};
