/* eslint-disable @next/next/no-img-element */
import cn from 'classnames';
import paypalLogo from 'public/images/paypal-logo.png';
import masterCardLogo from 'public/images/mastercard-logo.png';
import amexLogo from 'public/images/amex-logo.png';
import applePayLogo from 'public/images/apple-pay-logo.png';
import jcbLogo from 'public/images/jcb-logo.png';
import visaLogo from 'public/images/visa-logo.png';
import discoverLogo from 'public/images/discover-logo.png';
import styles from './PaymentMethodsLogos.module.scss';

export type PaymentMethodsLogosProps = {
  className?: string;
};

const PAYMENT_METHODS = [
  {
    name: 'PayPal',
    logo: paypalLogo,
  },
  {
    name: 'Master Card',
    logo: masterCardLogo,
  },
  {
    name: 'Visa',
    logo: visaLogo,
  },
  {
    name: 'Amex',
    logo: amexLogo,
  },
  {
    name: 'JCB',
    logo: jcbLogo,
  },
  {
    name: 'Apple Pay',
    logo: applePayLogo,
  },
  {
    name: 'Discover',
    logo: discoverLogo,
  },
];

const PaymentMethodsLogos = ({ className = '' }: PaymentMethodsLogosProps) => {
  const getAlternativeText = (index: number, name: string) => {
    if (index === 0) return `We accept ${name},`;

    const lastPaymentMethodIndex = PAYMENT_METHODS.length - 1;

    return index !== lastPaymentMethodIndex ? `${name},` : `and ${name}.`;
  };

  return (
    <ul className={cn(styles.root, className)}>
      {PAYMENT_METHODS.map(({ name, logo }, index) => {
        return (
          <li key={name}>
            <img
              src={logo.src}
              alt={getAlternativeText(index, name)}
              loading="lazy"
              className={styles.logo}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default PaymentMethodsLogos;
