import Icon from 'src/core/svg-sprites/ui/atoms/Icon';

export type ContentManagerLogoProps = {
  className: string;
  size: 'mobile' | 'desktop';
};

const ContentManagerLogo = ({ className, size }: ContentManagerLogoProps) => {
  return (
    <Icon
      name={size === 'mobile' ? 'nebula-small' : 'nebula'}
      className={className}
      title="NEBULA"
    />
  );
};

export default ContentManagerLogo;
