import { normalizeError } from 'src/core/common/utils/errors';
import { AppThunk } from 'src/core/common/store';
import { UtmSource } from '../../entities';
import { userUtmTagsUpdated } from './actions';

export const markUtmSourceAsOrganic = (): AppThunk => async (dispatch, getState, services) => {
  const { utmTagsService, analyticsService, logger } = services;
  const isUtmSourceAlreadyExist = !!utmTagsService.getUtmSource();

  if (isUtmSourceAlreadyExist) return;

  try {
    const updatedUtmTags = utmTagsService.updateUtmTags({ utm_source: UtmSource.ORGANIC });
    await analyticsService.updateUtmTags(updatedUtmTags);

    dispatch(userUtmTagsUpdated(updatedUtmTags));
  } catch (err) {
    const error = normalizeError(err);

    logger.error(error);
  }
};
