import { useState } from 'react';
import cn from 'classnames';
import RenderOnlyOnClient from 'src/core/common/components/common/RenderOnlyOnClient';
import { ContentManagerHeader as ContentManagerHeaderEntity } from 'src/core/contentManager/entities';
import { PreferredTheme } from 'src/core/theme/entities';
import useTheme from 'src/core/theme/hooks/useTheme';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import ThemeSwitch from 'src/core/theme/ui/atoms/ThemeSwitch';
import Drawer from 'src/core/common/components/common/Drawer';
import { checkIsClientSide } from 'src/core/common/utils/checkIsClientSide';
import HeaderDrawerItem from '../../molecules/HeaderDrawerItem';
import styles from './HeaderDrawer.module.scss';

export type HeaderDrawerProps = {
  className?: string;
  header: ContentManagerHeaderEntity;
  onLinkSelect: (link: string) => void;
};

const DRAWER_CONTAINER_ID = 'drawer-container';

export const HeaderDrawer = ({ className, header, onLinkSelect }: HeaderDrawerProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const { currentTheme, changeTheme, otherTheme } = useTheme();

  const onControlClick = () => {
    setIsOpened((value) => !value);
  };

  const onClose = () => {
    setIsOpened(false);
  };

  const drawerClasses = cn(styles.menuContentWrapper, 'themeRoot');

  return (
    <div className={cn(styles.root, className)} id={DRAWER_CONTAINER_ID}>
      <button
        className={styles.menuControl}
        onClick={onControlClick}
        aria-label="Open menu"
        data-testid="header-drawer-button"
      >
        <Icon name="burger" className={styles.menuControlIcon} />
      </button>
      <Drawer
        open={isOpened}
        onClose={onClose}
        PaperProps={{
          className: drawerClasses,
        }}
        keepMounted
        ModalProps={{
          container: checkIsClientSide() ? document.getElementById(DRAWER_CONTAINER_ID) : null,
        }}
      >
        <div className={styles.menuContent}>
          <div className={styles.menuCloseWrapper}>
            <button className={styles.menuClose} onClick={onClose} aria-label="Close menu">
              <Icon name="cross" className={styles.menuCloseIcon} />
            </button>
          </div>
          <nav className={styles.menuNavigation}>
            <ul className={styles.menuNavLinkList}>
              {header.data.map((item) => (
                <HeaderDrawerItem
                  key={`${item.label}-${item.href}-${isOpened}`}
                  item={item}
                  onCloseDrawer={onClose}
                  onLinkSelect={onLinkSelect}
                />
              ))}
            </ul>
            <ul className={styles.menuNavActionsList}>
              <li className={styles.menuNavAction}>
                <RenderOnlyOnClient>
                  Switch Mode
                  <ThemeSwitch
                    checked={currentTheme === PreferredTheme.LIGHT}
                    onChange={changeTheme}
                    aria-label={`Change theme to ${otherTheme}`}
                    data-testid="theme-switch-toggle"
                  />
                </RenderOnlyOnClient>
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderDrawer;
