import cn from 'classnames';
import { ReactNode } from 'react';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import { AnyString } from 'src/core/common/entities';
import styles from './SocialLink.module.scss';

export type SocialLinkProps = {
  className?: string;
  type: 'facebook' | 'instagram' | 'youtube' | 'twitter' | 'tiktok' | 'threads' | AnyString;
  href: string;
  onSelect?: (social: SocialLinkProps['type']) => void;
  ariaLabel: string;
};

const LOGO_MAP: Record<SocialLinkProps['type'], ReactNode> = {
  facebook: <Icon name="facebook" className={cn(styles.linkLogo, styles.facebookLogo)} />,
  instagram: <Icon name="instagram" className={cn(styles.linkLogo, styles.instagramLogo)} />,
  tiktok: <Icon name="tiktok" className={cn(styles.linkLogo, styles.tiktokLogo)} />,
  twitter: <Icon name="twitter" className={cn(styles.linkLogo, styles.twitterLogo)} />,
  youtube: <Icon name="youtube" className={cn(styles.linkLogo, styles.youtubeLogo)} />,
  threads: <Icon name="threads" className={cn(styles.linkLogo, styles.threadsLogo)} />,
};

const SocialLink = ({ className = '', type, href, onSelect, ariaLabel }: SocialLinkProps) => {
  const onClick = () => {
    if (onSelect) {
      onSelect(type);
    }
  };

  return (
    <a
      href={href}
      target="_blank"
      className={cn(styles.root, className)}
      rel="noreferrer"
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {LOGO_MAP[type] && <span className={styles.linkInner}>{LOGO_MAP[type]}</span>}
    </a>
  );
};

export default SocialLink;
