import cn from 'classnames';
import { useState } from 'react';
import Link from 'src/core/common/components/common/Link';
import { ContentManagerHeader } from 'src/core/contentManager/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './HeaderDrawerItem.module.scss';

type HeaderDrawerItemProps = {
  item: NonNullable<ContentManagerHeader>['data'][number];
  className?: string;
  onCloseDrawer: () => void;
  onLinkSelect: (link: string) => void;
};

const HeaderDrawerItem = ({
  item,
  className,
  onCloseDrawer,
  onLinkSelect,
}: HeaderDrawerItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onLinkClick = () => {
    onCloseDrawer();
    onLinkSelect(item.href);
  };

  const submenuClasses = cn({
    [styles.submenu]: true,
    [styles.submenuOpened]: isOpen,
    [styles.submenuClosed]: !isOpen,
  });

  if (!!item.children.length)
    return (
      <li className={cn(styles.root, className)}>
        <button className={styles.openButton} onClick={onOpen}>
          {item.label} <Icon name="arrow-simple" className={styles.openArrow} />
        </button>
        <div className={submenuClasses}>
          <p className={styles.submenuTitle}>
            <button
              onClick={onClose}
              aria-label={`Close ${item.label} menu`}
              className={styles.closeButton}
            >
              <Icon name="arrow-simple" className={styles.closeArrow} />
            </button>
            <span className={styles.submenuTitleInner}> {item.label}</span>
          </p>

          <ul className={styles.submenuList}>
            {item.children.map((child) => (
              <HeaderDrawerItem
                key={`${child.label}-${child.href}`}
                item={child}
                onCloseDrawer={onCloseDrawer}
                onLinkSelect={onLinkSelect}
              />
            ))}
          </ul>
        </div>
      </li>
    );

  return (
    <li className={cn(styles.root, className)}>
      {item.href ? (
        <Link className={styles.menuNavLink} href={item.href} onClick={onLinkClick}>
          {item.label}
        </Link>
      ) : (
        item.label
      )}
    </li>
  );
};

export default HeaderDrawerItem;
