import dayJs from 'dayjs';

export const getDatesDifference = (
  dateA: Date | string | number,
  dateB: Date | string | number,
) => {
  if (dayJs(dateA).isAfter(dateB)) {
    [dateA, dateB] = [dateB, dateA];
  }

  return dayJs(dateB).diff(dateA);
};
