import { useCallback, useEffect, useState } from 'react';
import { useServices } from 'src/core/common/hooks';
import { MEDIA_QUERIES } from 'src/core/common/constants';
import { PreferredTheme } from 'src/core/theme/entities';
import { normalizeError } from 'src/core/common/utils/errors';

const useThemeProvider = (): [PreferredTheme, (theme: PreferredTheme) => Promise<void>] => {
  const { themeService, appEnvironmentService, logger } = useServices();

  const [theme, setTheme] = useState(() => {
    return themeService.getInitialTheme();
  });

  const updateTheme = useCallback(
    async (newTheme: PreferredTheme) => {
      try {
        setTheme(newTheme);
        await themeService.setTheme(newTheme);
      } catch (e) {
        const err = normalizeError(e);
        logger.error(err);
      }
    },
    [themeService, logger],
  );

  useEffect(() => {
    const darkTheme = appEnvironmentService.createMediaQuery(MEDIA_QUERIES.prefersDarkColorScheme);

    if (darkTheme) {
      const darkThemeListener = async ({ matches }: MediaQueryListEvent) => {
        matches && (await updateTheme(PreferredTheme.DARK));
      };

      if (darkTheme.addEventListener) {
        darkTheme.addEventListener('change', darkThemeListener);
      } else {
        darkTheme.addListener(darkThemeListener);
      }

      return () => {
        if (darkTheme.removeEventListener) {
          darkTheme.removeEventListener('change', darkThemeListener);
        } else {
          darkTheme.removeListener(darkThemeListener);
        }
      };
    }
  }, [setTheme, appEnvironmentService, updateTheme]);

  useEffect(() => {
    const lightTheme = appEnvironmentService.createMediaQuery(
      MEDIA_QUERIES.prefersLightColorScheme,
    );

    if (lightTheme) {
      const lightThemeListener = async ({ matches }: MediaQueryListEvent) => {
        matches && (await updateTheme(PreferredTheme.LIGHT));
      };

      if (lightTheme.addEventListener) {
        lightTheme.addEventListener('change', lightThemeListener);
      } else {
        lightTheme.addListener(lightThemeListener);
      }

      return () => {
        if (lightTheme.removeEventListener) {
          lightTheme.removeEventListener('change', lightThemeListener);
        } else {
          lightTheme.removeListener(lightThemeListener);
        }
      };
    }
  }, [setTheme, appEnvironmentService, updateTheme]);

  return [theme, updateTheme];
};

export default useThemeProvider;
