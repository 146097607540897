import { ContentManagerFooter } from '../entities';

export const defaultFooter: NonNullable<ContentManagerFooter> = {
  data: [
    {
      label: 'Nebula',
      children: [
        {
          href: '/press',
          label: 'Press',
        },
        {
          href: '/reviews',
          label: 'Nebula Reviews',
        },
      ],
    },
    {
      label: 'Support',
      children: [
        {
          href: '/support',
          label: 'Support',
        },
        {
          href: '/app/privacy-policy',
          label: 'Privacy Policy',
        },
      ],
    },
    {
      label: 'Contact Us',
      children: [
        {
          href: '/contact-us',
          label: 'Contact Us',
        },
        {
          href: '/faq',
          label: 'FAQ',
        },
      ],
    },
    {
      label: 'Account',
      children: [
        {
          href: '/signup',
          label: 'Sign Up | Sign In',
        },
        {
          href: '/careers',
          label: 'Join as a Psychic',
        },
      ],
    },
    {
      label: 'Reader near me',
      children: [
        {
          href: '/psychic-near-me',
          label: 'Psychic Near me',
        },
        {
          href: '/tarot-reading-near-me',
          label: 'Tarot Reading Near Me',
        },
      ],
    },
  ],
};
