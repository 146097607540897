import Head from 'next/head';
import { Fragment } from 'react';
import { useDefaultCanonicalUrl } from 'src/core/common/hooks';
import { isObjectEmpty } from 'src/core/common/utils/isObjectEmpty';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';
import { withAppOrigin } from 'src/core/common/utils/url';
import { ContentManagerMetaData } from 'src/core/contentManager/entities';
import {
  renderSocialNetworkMetaData,
  renderSocialNetworkMetaDataType,
} from 'src/core/contentManager/utils/renderSocialNetworkMetaData';

const MetaData = (metaDataProps: NonNullable<ContentManagerMetaData>) => {
  const {
    canonicalURL,
    keywords,
    metaDescription,
    metaRobots,
    metaTitle,
    metaImage,
    structuredData,
    socials,
  } = metaDataProps;

  const { defaultCanonicalUrl } = useDefaultCanonicalUrl();

  const canonicalUrl = canonicalURL ? withAppOrigin(canonicalURL) : defaultCanonicalUrl;

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      <meta name="theme-color" content="#000000" />

      <link rel="canonical" key="canonical" href={canonicalUrl} />

      {keywords && <meta name="keywords" content={keywords} />}

      {socials.map((social) => {
        if (
          typeof renderSocialNetworkMetaData[
            social.socialNetwork as keyof renderSocialNetworkMetaDataType
          ] === 'function'
        ) {
          return (
            <Fragment key={social.id}>
              {renderSocialNetworkMetaData[
                social.socialNetwork as keyof renderSocialNetworkMetaDataType
              ](social, canonicalUrl)}
            </Fragment>
          );
        }
      })}

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />

      {metaImage && (
        <>
          <meta property="og:image:url" content={metaImage.url} />
          <meta property="og:image:width" content={metaImage.width.toString()} />
          <meta property="og:image:height" content={metaImage.height.toString()} />
          <meta property="og:image:alt" content={metaImage.alt} />
        </>
      )}

      {metaRobots && <meta name="robots" content={metaRobots} />}

      {structuredData.map(({ id: jsonId, json }) => {
        return !isObjectEmpty(json) ? (
          <script
            key={`json-ld-${jsonId}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={prepareHtml(JSON.stringify(json))}
          />
        ) : null;
      })}
    </Head>
  );
};

export default MetaData;
