import ImageLib, { ImageProps as NextImageProps } from 'next/legacy/image';
import FutureImageLib, { ImageProps as FutureNextImageProps } from 'next/image';

export type ImageProps =
  | ({ type?: 'default' } & NextImageProps)
  | ({ type?: 'future' } & FutureNextImageProps);

const isFutureImage = (
  type: ImageProps['type'],
  image: ImageProps,
): image is FutureNextImageProps => type === 'future';

const Image = (props: ImageProps) => {
  const { type = 'default' } = props;

  if (isFutureImage(type, props)) return <FutureImageLib {...props} />;

  return <ImageLib {...props} />;
};

export default Image;
