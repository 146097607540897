import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlowName } from 'src/core/quiz/entities';
import { signUpViaGoogle } from 'src/core/user/store/thunks';
import { normalizeError } from 'src/core/common/utils/errors';
import { useLogger } from 'src/core/common/hooks';
import { GoogleLoginData, SignUpGoogleResponseData } from '../entities';

export const useGoogleSignUp = (flowName: FlowName) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  const logger = useLogger();

  const signUpGoogle = useCallback(async (data: SignUpGoogleResponseData): Promise<void> => {
    setIsDisabled(true);

    const { profileObj, tokenId } = data as GoogleLoginData;
    const { email } = profileObj;

    const result = {
      token: tokenId,
      quizName: flowName,
      email,
    };

    try {
      await dispatch(signUpViaGoogle(result));
      setIsDisabled(false);
    } catch (e) {
      // TODO think about error message
      const err = normalizeError(e);
      logger.error(err);
      setIsDisabled(false);
    }
  }, []);

  return {
    isDisabled,
    signUpGoogle,
  };
};
