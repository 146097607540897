import { Children, cloneElement, isValidElement, ReactNode } from 'react';
import { isValidDomElement } from 'src/core/common/utils/isValidDomElement';

type ModificationObject = {
  props?: Record<string, unknown>;
  className?: string;
};

export const mapDomElementsWithModification = (
  children: ReactNode,
  modificationObject: ModificationObject,
): ReactNode => {
  let areChangesApplied = false;
  return Children.map(children, (child) => {
    if (isValidDomElement(child) && !areChangesApplied) {
      areChangesApplied = true;
      const existingProps = child.props;
      const existingClasses = existingProps.className || '';
      const newProps = {
        ...existingProps,
        ...modificationObject.props,
        className: `${existingClasses} ${modificationObject.className}`,
      };
      return cloneElement(child, newProps);
    }

    if (isValidElement(child) && !!child.props.children) {
      return cloneElement(child, {
        children: mapDomElementsWithModification(child.props.children, modificationObject),
      });
    }

    return child;
  });
};
