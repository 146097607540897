import { ChangeEvent } from 'react';
import { useSafeContext } from 'src/core/common/hooks';
import { ThemeStateContext, ThemeUpdaterContext } from 'src/core/theme/context/ThemeProvider';
import { PreferredTheme } from 'src/core/theme/entities';

export const useTheme = () => {
  const currentTheme = useSafeContext(ThemeStateContext);
  const setThemeState = useSafeContext(ThemeUpdaterContext);

  const otherTheme =
    currentTheme === PreferredTheme.LIGHT ? PreferredTheme.DARK : PreferredTheme.LIGHT;
  const changeTheme = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setThemeState(PreferredTheme.LIGHT) : setThemeState(PreferredTheme.DARK);
  };

  return { currentTheme, changeTheme, otherTheme };
};

export default useTheme;
