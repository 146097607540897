import { ComponentType, ReactNode, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import {
  ContentManagerFooter as ContentManagerFooterEntity,
  ContentManagerHeader as ContentManagerHeaderEntity,
  ContentManagerMetaData as MetaDataEntity,
} from 'src/core/contentManager/entities';
import LastSeenModalProvider from 'src/core/contentManager/context';
import { defaultHeader } from 'src/core/contentManager/constants/defaultHeader';
import { defaultFooter } from 'src/core/contentManager/constants/defaultFooter';
import { useUserUtmTags } from 'src/core/user/hooks';
import {
  useLastSeenModal,
  UseLastSeenModalParams,
} from 'src/core/contentManager/hooks/useLastSeenModal';
import FixedSupportLink from '../../atoms/FixedSupportLink';
import MetaData from '../../organisms/MetaData';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';

const LazyFreeReadingModal = dynamic(
  () => import('src/core/common/components/common/FreeReadingModal'),
  {
    ssr: false,
  },
);

export type ContentManagerLayoutProps = {
  children: ReactNode;
  metaData?: MetaDataEntity | null;
  header?: ContentManagerHeaderEntity;
  footer?: ContentManagerFooterEntity;
  banner?: ReactNode;
  classes?: {
    headerClass?: string;
  };
  lastSeenModal?: {
    component?: ComponentType<{
      open: boolean;
      onClaim: () => void;
      onClose: () => void;
      renderText?: () => ReactNode;
    }>;
    params: UseLastSeenModalParams;
    renderText?: () => ReactNode;
  };
};

export const ContentManagerLayout = ({
  children,
  metaData,
  header = defaultHeader,
  footer = defaultFooter,
  banner,
  classes = { headerClass: '' },
  lastSeenModal = { params: {} },
}: ContentManagerLayoutProps) => {
  const { markUtmSourceAsOrganic } = useUserUtmTags();

  useEffect(() => {
    markUtmSourceAsOrganic();
  }, [markUtmSourceAsOrganic]);

  const {
    onOpen: onLastSeenModalOpen,
    onClose: onLastSeenModalClose,
    onConfirm: onLastSeenModalConfirm,
    shouldSkip: shouldSkipLastSeenModal = false,
  } = lastSeenModal.params;

  const { closeModal, showModal, confirmModal, clearModalShowTimer } = useLastSeenModal({
    onOpen: onLastSeenModalOpen,
    onClose: onLastSeenModalClose,
    onConfirm: onLastSeenModalConfirm,
    shouldSkip: shouldSkipLastSeenModal,
  });

  const LastSeenModal = useMemo(() => {
    return lastSeenModal.component ?? LazyFreeReadingModal;
  }, [lastSeenModal?.component]);

  const { headerClass } = classes;

  const renderModalTextDefault = () => 'Get your first psychic reading for FREE';

  return (
    <LastSeenModalProvider cancel={clearModalShowTimer}>
      {metaData ? <MetaData {...metaData} /> : null}

      <Header header={header} banner={banner} className={headerClass} />
      {children}
      <Footer footer={footer} />

      <FixedSupportLink />

      {!shouldSkipLastSeenModal && showModal && (
        <LastSeenModal
          open={showModal}
          onClaim={confirmModal}
          onClose={closeModal}
          renderText={lastSeenModal?.renderText ?? renderModalTextDefault}
        />
      )}
    </LastSeenModalProvider>
  );
};

export default ContentManagerLayout;
