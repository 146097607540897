import { SwitchProps as MaterialSwitchProps } from '@mui/material';
import { StyledMaterialSwitch } from './styles';

export type ThemeSwitchProps = MaterialSwitchProps & {
  className?: string;
};

const ThemeSwitch = ({ className, ...otherProps }: ThemeSwitchProps) => {
  return <StyledMaterialSwitch disableRipple className={className} {...otherProps} />;
};

export default ThemeSwitch;
