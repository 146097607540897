export enum UtmSource {
  FACEBOOK = 'fb',
  GOOGLE = 'google',
  TIKTOK = 'tiktok',
  EMAIL = 'email',
  AFFILIATE = 'affiliate',
  MOONSHOT = 'moonshot',
  CPAMATICA = 'cpamatica',
  MYSTICMAG = 'mysticmag',
  DEMETRAMEDIA = 'demetramedia',
  ORGANIC = 'organic',
  NATURALINT = 'naturalint',
  FLEX = 'flex',
  MAXIME = 'maxime',
  EXPLA = 'expla',
  TAROT_SET = 'tarot_set',
}

export type UtmTags = Partial<{
  utm_source: UtmSource;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
  utm_campaign_id: string;
  utm_campaignid: string;
  utm_campaign: string;
  utm_adset_id: string;
  utm_adsetid: string;
  utm_adset: string;
  utm_ad_id: string;
  utm_adid: string;
  utm_ad: string;
  gclid: string;
  iterable_message_id: string;
  fbp: string;
  fbc: string;
  fbclid: string;
  ga: string;
  everflow_transaction_id: string;
  utm_source_retention: string;
  utm_adset_name: string;
  utm_placement: string;
  utm_ad_name: string;
  ttclid: string;
  quizz: string;
  oid: string;
  _ef_transaction_id: string;
  fb_test_event_code: string;
}>;

export type User = {
  id: string;
  email: string;
  nebula_id: number;
  nebula_uuid: string;
  name: string;
  gender: string;
  phone_number?: string;
  birth_day: string;
  birth_time: string;
  birth_place: string;
  created_at: number;
  funnel: string;
};
